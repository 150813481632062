@import "../../../../var.scss";
@import "../../../common.scss";

.selectOrganization {
  > .table {
    padding-left: 10px;
    min-width: 800px;
    background-color: $white;
    border-radius: 20px;
  }
}