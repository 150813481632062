.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__top {
    margin-top: 50px;
    margin-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_loader {

    }

    &_text {
      margin-top: 16px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #3956C7;
    }
  }

  &_img {
    height: 200px;
  }
}