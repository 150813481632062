@import '../../var.scss';

.photoCellsRow {
  display: flex;

  .photo {
    object-fit: cover;
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
    width: 22px;
    height: 22px;
    border: 1px solid $white;
    box-sizing: border-box;
    border-radius: 50%;
  }

  .noPhoto {
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: $white;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    text-align: center;
    box-sizing: border-box;
  }

  .photo, .noPhoto {
    &:not(:first-child) {
      margin-left: -11px;
    }
  }
}