@import "../../../../var.scss";

.picker {
  position: relative;

  .closeIcon {
    position: absolute;
    right: 5px;
    top: 10px;

    &_label {
      top: 35px;
    }

    &:hover {
      cursor: pointer;
      background-color: $grayBg;
      border-radius: 50%;
    }
  }

  &_inner {

    span {
      position: absolute;
      left: 8px !important;
    }

    input {
      height: 48px !important;
      padding-left: 2.35rem !important;
      padding-right: 2rem !important;
      border: 1px solid $border !important;
      border-radius: 8px !important;
      color: #7E808F !important;
      font-size: 16px !important;
      background-color: $lightGray;
  
      &:hover {
        border-color: $borderHover;
        transition: border-color 0.05s linear;
        background-color: $lightGray;
      }
  
  
      &:focus {
        border: 1px solid $borderActive !important;
        box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0.07) !important;
        transition: box-shadow 0.13s ease-in-out !important;
        background-color: $lightGray;
  
        &:hover {
          border-color: $borderActive;
          background-color: $lightGray;
        }
      }
    }

    &_error {
      input {
        border-color: #E7313C !important;
      }
    }
  }

  &_label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &_disabled {
    opacity: 0.5;
  }

  &_error {
    position: absolute;
    top: 3px;
    right: 0;
    color: $warning;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}