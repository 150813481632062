$primary01: #3956C7;
$hover01: #1C2F8F;
$primary02: #1C3BCF; //temp
$lightGray: #F8FAFF;
$textLightGray: #959EBA;
$white: #FFFFFF;
$borderGray: #DDE2F2;
$borderLightBlue: #8DBBE5;
$borderLightGray: #F2F4F8;
$darkGray: #333333;
$pink: #E48DE5;
$gray: #7E808F;
$grayBg: #DBDCE4;
$grayBg2: #DAE0EE;
$border: #DDE2F2;
$borderLight: #EDF0FA;
$borderHover: #e7e9f3;
$borderActive: #3956C7;
$warning: #E7313C;
$statusLow: #19CB56;
$statusMedium: #ffa500;
$statusHigh: #E82020;
$deleteRed: #DB1818;
$borderRed: #FD8F52;
$backgroundLightRed: #FFF7F2;
$backgrounLightPurple: #E1E8FF;
$backroundLightGray: rgba(57, 86, 199, 0.15);
$backgroundGray3: #EBEEF9;
$backgroundGray4: #CFD5E9;
$green: #0DAF45;