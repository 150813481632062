@import "../../var.scss";
@import "../../breakpoints.scss";
@import "../common.scss";

.resetPass {
  background-color: $primary01;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .page;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &_logo {
      margin-bottom: 56px;
      width: 140px;
      height: 32px;
    }
  }

  &__form {
    padding: 40px;
    background-color: white;
    border-radius: 16px;
    max-width: 450px;
    width: 100%;
    z-index: 2;

    &_title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      text-align: center;
    }

    &_subTitle {
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      line-height: 24px;
      text-align: center;
    }

    &_input {
      margin-top: 24px;
    }

    &_btn {
      margin-top: 56px;
      width: 100%;
    }

    & > .goToLogin {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3956C7;
      width: 100%;
      text-align: center;
      cursor: pointer;
      width: fit-content;
      margin: 0 auto;
      margin-top: 24px;
    }

    & > .done {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      & > .img {
        min-width: 88px;
        min-height: 88px;
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0DAF45;
        border-radius: 50%;
      }
    
      & > .success {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #0DAF45;
        margin-top: 24px;
      }

      & > .text {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          margin-top: 8px;
        }
      }
    
      & > .backBtn {
        margin-top: 50px;
        width: 100%;
      }
    }
  }

  &_back {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
  }
}

@media (max-width: $mobile) {
  .resetPass {
    &__form {
      padding: 24px 20px;
  
      &_title {
        font-size: 20px;
        line-height: 24px;
      }
  
      &_subTitle {

      }
  
      &_input {
        margin-top: 16px;
      }
  
      &_btn {
        margin-top: 36px;
        width: 100%;
      }
  
      & > .goToLogin {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #3956C7;
        width: 100%;
        text-align: center;
        cursor: pointer;
        width: fit-content;
        margin: 0 auto;
        margin-top: 24px;
      }
  
      & > .done {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        & > .img {
          min-width: 88px;
          min-height: 88px;
          width: 88px;
          height: 88px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #0DAF45;
          border-radius: 50%;
        }
      
        & > .success {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #0DAF45;
          margin-top: 18px;
        }
  
        & > .text {
          
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            margin-top: 8px;
          }
        }
      
        & > .backBtn {
          margin-top: 30px;
          width: 100%;
        }
      }
    }
  
    &_back {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      overflow: hidden;
    }
  }
}