@import "../../../../var.scss";

.history { 
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    > .filters {

        > .visibleFilters {
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            > .search {
                height: 42.5px;
                margin-right: 24px;
                max-width: 256px;
                min-width: 200px;
                font-weight: 400;
                font-size: 14px;
                color: $darkGray;

                > input {
                    font-size: 14px !important;
                    color: $darkGray;
                    height: 40.5px !important;

                    &::placeholder {
                        font-size: 14px !important;
                        color: $gray;
                    }
                }
            }

            > .clearFilters {
                &:hover {
                    cursor: pointer;
                }

                > .text {
                    text-decoration-line: underline;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: $primary01;
                }
            }
        }
        
        > .hiddenFilters {
            display: flex;
            margin-top: 16px;
            justify-content: space-between;
            align-items: center;

            > .form {
                display: flex;
                
                > .priority {
                    margin-right: 16px;
                    max-width: 148px;
                    min-width: 120px;
                    height: 42px;

                    div {
                        font-size: 14px;
                        color: $darkGray;
                    }
                    
                    &_placeholder {
                        div {
                            color: $gray;
                        }
                    }
                }
    
                > .staff {
                    margin-right: 16px;
                    max-width: 200px;
                    min-width: 170px;
                    height: 42px;
                    
                    div {
                        font-size: 14px;
                        color: $darkGray;
                    }

                    &_placeholder {
                        div {
                            color: $gray;
                        }
                    }
                }
                
                > .dateRange {
                    height: 42px;
                    margin-top: -28px;
                    min-width: 255px;
                    margin-right: 16px;

                    input {
                        height: 42.5px !important;
                        font-size: 14px !important;

                        &::placeholder {
                            font-size: 14px !important;
                        }
                    }
                }

                > .radio_input {
                    div {
                        font-size: 14px !important;
                    }
                }
            }

            > .downloadUnreadUpdates {
                display: flex;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }

                > .icon {
                    margin-right: 8px;
                }
            
                > .text {
                    font-size: 14px;
                    line-height: 24px;
                    text-decoration-line: underline;
                    color: $darkGray;
                }
            }
        }
    }


    > .list {
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;
        flex-grow: 1;

        > .card {
            margin-top: 24px;

            &:first {
                margin-top: 0;
            }
        }
    }

    > .loader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 200px;
    }
}