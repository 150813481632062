@import "../../../../../var.scss";

.residentCardInfo {
    position: relative;
    padding: 36px 24px 24px 24px;
    background: $white;
    box-shadow: 0px 0px 20px -3px rgba(216, 216, 226, 0.2);
    border-radius: 16px;

    > .adminActions {
        display: flex;
        position: absolute;
        top: 12px;
        right: 16px;

        > .disableResident {
            display: flex;
            align-items: center;
            color: $deleteRed;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-right: 12px;

            &:hover {
                cursor: pointer;
            }
    
            > .icon {
                margin-right: 6px;
            }
        }

        > .editResident {
            display: flex;
            align-items: center;
            color: $primary01;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
    
            &:hover {
                cursor: pointer;
                color: $hover01;
            }
    
            > .icon {
                margin-right: 6px;
            }
        }
    }

    > .header {
        display: flex;
        align-items: center;
        padding: 0px 24px 24px 24px;
        border-bottom: 1px solid #F2F4F8;
        
        > .info {
            display: flex;
            flex-direction: column;

            > .firstRow {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                > .name {
                    margin-right: 8px;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    color: $darkGray;
                    white-space: nowrap;
                }

                > .age {
                    font-size: 16px;
                    line-height: 24px;
                    color: $darkGray;
                    white-space: nowrap;
                }
            }

            > .home {
                margin-top: 4px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $darkGray;
            }
            
            > .place {
                margin-top: 4px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $darkGray;

                > .bold {
                    font-weight: 600;
                }
            }
        }
    }

    > .infoRows {
        padding: 0 24px 8px 24px;
        background-color: $white;

        > .infoRow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0;

            &:not(:first-child) {
                border-top: 1px solid $borderLightGray;
            }

            > .title {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $gray;
            }

            > .value {
                text-align: right;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $darkGray;

                &_capitalize {
                    text-transform: capitalize;
                }
            }

            > .allergies {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                max-width:70%;
                margin-top: -8px;
                
                > .allergy {
                    padding: 0px 16px;
                    background: $borderLightGray;
                    border-radius: 13px;
                    margin-top: 8px;
                    margin-left: 8px;
                    font-size: 14px;
                    line-height: 24px;
                    color: $darkGray;
                    text-align: center;
                }
            }

            > .radio {
                span {
                    font-family: "Poppins";
                    font-size: 16px;
                    color: $darkGray;
                }
            }
        }
    }
}

.photo, .noPhoto {
    width: 104px;
    height: 104px;
    min-width: 104px;
    min-height: 104px;
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;
}

.noPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: $white;
}