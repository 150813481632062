@import "../../../../var.scss";

.announcementsTab { 
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    > .list {
        display: flex;
        flex-direction: column;
        padding-bottom: 24px;

        > .announcement {
            margin-top: 16px;

            &:first {
                margin-top: 0;
            }
        }
    }

    > .loader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 200px;
    }
}