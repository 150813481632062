
@import '../../var.scss';

.loginTechSupportSentModalContainer {

  > .content {
    > .icon {
      margin: 0 auto;
    }

    > .textInfo {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      text-align: center;

      > .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: $darkGray;
        margin-bottom: 8px;
      }

      > .text {
        font-size: 16px;
        line-height: 24px;
        color: $darkGray;

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }
}