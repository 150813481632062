.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 64px;

  > .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-height: 768px;

    > .left {
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding-top: 40px;
      max-width: 330px;
      width: 100%;
      height: 100%;
  
      > .text404 {
        font-weight: 400;
        font-size: 48px;
        line-height: 48px;
        color: #DDE2F2;
      }
  
      > .text {
        margin-top: 8px;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #1C3177;
      }
  
      > .description {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
  
      > .button {
        width: 275px;
        margin-top: 40px;
      }
    }
  
    > .right {
      display: flex;
      flex-direction: column;
      justify-content: end;
      height: 100%;

      > .errorDog {
        width: 100%;
        min-width: 510px;
      }
    }
  }

}
