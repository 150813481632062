.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &_full {
    width: 100% !important;
    height: 100% !important;
  }
}

.loader {
  border-style: solid;
  border-radius: 50%;
  animation: load 1.4s infinite linear;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}