.btn {
  cursor: pointer;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > .img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(219, 24, 24, 0.05);
    border: 1px solid #DB1818;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  & > .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-top: 16px;
    text-align: center;
  }

  & > .subTitle {
    font-size: 16px;
    font-weight: 300;
    margin-top: 26px;
    text-align: center;
  }

  & > .btn {
    width: 100%;
    margin-top: 40px;
  }

  & > .cancel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
    color: #3956C7;
    cursor: pointer;
  }
}