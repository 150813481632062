@import "../../var.scss";

.appHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 0;

    > .adminHeader {
        width: 100%;

        > .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .title {
                min-height: 48px;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: $darkGray;
            }

            > .newEmployeeBtn {
                height: 48px;
                width: 48%;
                max-width: 235px;
                min-width: 100px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;
                padding: 12px 34px;
                background-color: $borderActive;
                border: 1px solid $borderActive;

                div {
                    overflow: hidden;
        
                    span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
    
                }
            }

            > .newAgencyAccountBtn {
                height: 48px;
                width: 48%;
                max-width: 295px;
                min-width: 100px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;
                padding: 12px 19px;
                background-color: $borderActive;
                border: 1px solid $borderActive;

                div {
                    overflow: hidden;
        
                    span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
    
                }
            }
        }

        .nav {
            display: flex;
            align-items: center;
            margin-top: 16px;

            &__item {
                margin-right: 32px;
                font-size: 16px;
                line-height: 24px;
                color: $darkGray;
                text-decoration: none;
                
                &_active {
                    padding: 4px 32px;
                    background: $backroundLightGray;
                    border-radius: 22px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: $primary01;
                }
            }
        }
    }

    > .settings {
        display: flex;
        flex-direction: column;
        
        > .title {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $darkGray;
        }

        > .loginDate {
            margin-top: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $gray;
        }
    }

    > .settingsRight {
        display: flex;

        > .changeOrganization {
            margin-right: 20px;

            > .changeOrganizationBtn {
                height: 48px;
                padding: 0 8px;
            }
        }

        > .logout {
            display: flex;
            align-items: center;
    
            &:hover {
                cursor: pointer;
            }
            
            > .icon {
                margin-right: 8px;
            }
    
            > .text {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: $warning;
            }
        }
    }

    > .blockTitle {
        display: flex;
        flex-direction: column; 

        &_residents {
            padding-left: 8px;
        }

        > .title {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $darkGray;
    
            > .adminIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                border-radius: 5px;
                margin-left: 8px;
                background-color: $primary01;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;
    
                &:hover {
                    cursor: pointer;
                    background-color: $hover01;
                }
            }
        }

        > .residentsCount {
            width: 410px;
            margin-top: 8px;

            > .info {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 24px;
                
                > .text {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: $darkGray;
    
                    > .bold {
                        font-weight: 500;
                    }
                }
    
                > .percents {
                    display: flex;
                    align-items: center;
    
                    > .text {
                        font-size: 14px;
                        line-height: 22px;
                        color: $darkGray;
                        margin-right: 4px;
                    }
    
                    > .confirmedIcon {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            > .progressBar {
                margin-top: 4px;

                > .bar {
                    height: 6px;
                    background-color: $grayBg2;
                    > span {
                        background-color: $pink;
                    }
                }
            }
        }
    }

    > .title {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: $darkGray;
        
        .blueBold {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $primary01;
        }
    }

    > .agencyForgotPassword {
        display: flex;
        flex-direction: column;

        > .title {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $darkGray;
        }

        .subTitle {
            padding-top: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $darkGray;
        }
    }

    > .agencySetPassword {
        display: flex;
        flex-direction: column;

        > .title {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $darkGray;
        }

        > .subTitle {
            padding-top: 8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $darkGray;

            .bold {
                font-weight: 600;
            }
        }
    }

    
    > .resendInvitation {
        transform: translateY(18px);
        display: flex;
        flex-direction: column;

        > .invitations {
            margin-top: 8px;

            .dropdown {
                margin-right: 5px;

                .dropdown__text {
                    margin-right: 5px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    text-decoration-line: underline;
                    color: $darkGray;
                }
            }
        }
    }

    > .backToUpdates {
        height: 48px;
        padding: 12px 26px;
        border: 1px solid $border;
        background-color: $lightGray;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $darkGray;
    }

    > .menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > .icon {
            margin-right: 6px;
        }
        
        > .select {
            display: flex;
            align-items: center;

            > .dropdown {
                .dropdown__text {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: 10px;
                }
            }


            >.icon {

            }
        }
    }

    > .buttons {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > .backBtn {
            height: 48px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $darkGray;
            background-color: $lightGray;
            padding: 12px 23px;
            border: 1px solid $borderGray;
        }

        > .backBtnList {
            height: 48px;
            width: 48%;
            max-width: 208px;
            min-width: 100px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $darkGray;
            background-color: $lightGray;
            padding: 12px 0px;
            border: 1px solid $borderGray;

            div {
                overflow: hidden;
    
                span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

            }
        }

        > .acrchive {
            margin-right: 12px;
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
            color: $gray;
        }

        > .newUpdateBtn {
            height: 48px;
            width: 48%;
            max-width: 218px;
            min-width: 65px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $white;
            padding: 12px 0px;
            background-color: $borderActive;
            border: 1px solid $borderActive;
            margin-left: 12px;

            div {
                overflow: hidden;
    
                span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

            }
        }

        > .newAnnouncementBtn {
            height: 48px;
            width: 48%;
            max-width: 218px;
            min-width: 65px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $white;
            padding: 12px 0;
            background-color: $borderActive;
            border: 1px solid $borderActive;

            div {
                overflow: hidden;
    
                span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

            }
        }
    }
}

.update {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 38px;
    line-height: 38px;
    position: relative;

    > .backBtn {
        position: absolute;
        bottom: -25px;
        left: 61px;
    }
}

.photo {
    box-sizing: border-box;
    border-radius: 50%;
    width: 81px;
    height: 81px;
    min-height: 41px;
    min-width: 41px;
    margin-right: 16px;
    object-fit: cover;

    &_multiple {
        &:not(&:first-child) {
            margin-left: -36px;
        }
    }

    &_border {
        border: 1px solid $lightGray;
    }
}
  
.noPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 81px;
    min-height: 81px;
    border-radius: 50%;
    background: $grayBg;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    color: $white;
    text-transform: uppercase;
    margin-right: 16px;
}

.gMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > .icon {
        margin-right: 6px;
    }

    > .select {
        display: flex;
        align-items: center;

        > .dropdown {
            .dropdown__text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding: 10px;
            }
        }


        >.icon {

        }
    }
}