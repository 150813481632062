@import "../../../../var.scss";

.paper {
  padding: 6px;
  border-radius: 9px;
  
  li {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #333333;
    background-color: #ffffff !important;

    &:hover {
      background-color: #ffffff;
      font-weight: 600 !important;
    }
  }
}

.input {
  width: 100% !important;

  > .menuItem {
    border: 10px solid black !important;
    margin: 100px !important;
    
    > li {
    border: 10px solid black !important;
    background-color: black !important;
    }
  }

  &_error {
    border: 1px solid $warning;
    box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0);
    transition: box-shadow 0.13s ease-in-out;
    border-radius: 8px;

    &:hover {
      border-color: $warning;
    }
  }
}

.selectInputWrapper {
  position: relative;
  width: 100%;

  &__placeHolder {
    position: absolute;
    bottom: 11px;
    left: 12px;
    color: #bbbeca;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  &_label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    span {
      color: $warning;
    }
  }

  &_error {
    position: absolute;
    top: 3px;
    right: 0;
    color: $warning;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &_disabled {
    opacity: 0.5;
  }
}