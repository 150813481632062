.radio {
  display: flex;
  flex-direction: row;
  align-items: center;

  &_inner {
    
  }

  &_label {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    margin-left: 16px;
    margin-left: 6px;
  }
}