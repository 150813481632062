.btn {
  cursor: pointer;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > .img {
    width: 210px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  & > .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-top: 16px;
    text-align: center;
  }

  & > .btn {
    width: 100%;
    margin-top: 40px;
  }

  & > .cancel {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
    color: #3956C7;
    cursor: pointer;
  }
}