@import "../../../../var.scss";

.residentTab {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 24px;

    &::-webkit-scrollbar {
        display: none;
    }



    > .cards {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(390px, auto));

        > .loader {
            height: 300px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    > .history {
        margin-top: 24px;

        > .header {
            display: flex;
            align-items: center;

            > .title {
                margin-right: 24px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: $darkGray;
            }

            > .downloadHistory {
                display: flex;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }

                > .icon {
                    margin-right: 8px;
                }

                > .text {
                    font-size: 14px;
                    line-height: 24px;
                    text-decoration-line: underline;
                    color: $darkGray;
                }
            }

            > .sort {
                display: flex;
                align-items: center;

                > .icon {
                    margin-right: 8px;
                }

                > .text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                }
            }
        }

        > .list {
            margin-top: 16px;

            > .loader {
                height: 300px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            > .item {
                margin-top: 16px;
            }
        }
    }
}
