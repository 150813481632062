.paragraph {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Pain_Pain {
  &_Select_Type_Of_Pain {
    margin-top: 0 !important;
    width: unset !important;
    margin-left: 32px !important;
  }

  &_row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.verticalAlign {
  margin: 32px auto 0 auto;
  width: 100% !important;
}

.padding {
  &_1 {
    padding-left: 24px;
  }
}

.fullWidth {
  width: 100% !important; 
}

.humanInput {
  width: 100% !important; 
}

.margin {
  &_1 {
    margin-left: 24px;
  }
  &_top {
    margin-top: 24px;
  }
}

@media (max-width: 900px) {
  .Pain_Pain {
    &_Select_Type_Of_Pain {
      margin-top: 24px !important;
      margin-left: 0 !important;
    }

    &_row {
      display: flex;
      flex-direction: column;
      align-items: unset;
      width: 70%;
    }

    &_human {
      width: 100% !important;
    }
  }
}