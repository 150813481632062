@import "../../../../var.scss";

.radioInput {
  display: flex;
  flex-direction: column;

  &_radio {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  &_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;

    &_text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    span {
      color: $warning;
      margin-left: 2px;
    }
  }

  &_error {
    color: $warning;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: 24px;
  }
}