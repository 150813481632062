@import '../../../../var.scss';

.fileInput {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding: 36px;
    box-sizing: border-box;
    border: 1px dashed #C7C9D3;
    background-color: #F1F3FA;

    &_loading {
      pointer-events: none;
    }

    &__avatarWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: auto;

        &_loading {
          background: rgba(196, 196, 196, 0.4);
          backdrop-filter: blur(4px);
        }

        .img {
          position: relative;

          .file {
            width: 136px;
            height: 136px;
            border-radius: 8px;
            object-fit: cover;
          }

          .closeIcon {
            z-index: 3;
            position: absolute;
            top: -8px;
            right: -8px;
            cursor: pointer;
          }

          .blur {
            z-index: 2;
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(196, 196, 196, 0.4);
            backdrop-filter: blur(4px);
            border-radius: 8px;
          }
  
          .loader {
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .fileName {
          margin: 8px auto 0 auto;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $darkGray;
        }
    }

    .blur {
      z-index: 2;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      background: rgba(196, 196, 196, 0.4);
      backdrop-filter: blur(4px);
      border-radius: 8px;
    }

    .loader {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_title {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 500;
    }
    &_subTitle {
      font-size: 14px;
      font-size: 24px;
      font-weight: 400;
    }
    &_btn {
        margin-top: 16px;
        width: 144px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $white;
        background-color: $primary01;
    }
    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      width: 150px;
      height: 36px;
      &_input{
        font-size: 0;
        opacity: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }