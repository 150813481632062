@import "../../../../var.scss";

.settings {
    margin-top: 16px;

    > .profileForm {
        > .row {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(368px, auto));
            gap: 32px;

            &_btn {
                grid-template-columns: repeat(1, minmax(218px, auto));
                justify-content: flex-end;
            }
            
            &:not(:first-child) {
                margin-top: 32px;
            }

            > .email {
                position: relative;

                > .input {
                    flex: 1;
                    min-width: 368px;
    
                    input {
                        color: $darkGray !important;
                    }
    
                    &_disabled {
                        opacity: 1;
                    }
                }

                > .confirmedIcon {
                    position: absolute;
                    top: -2px;
                    left: 50px;
                }
            }

            > .input {
                flex: 1;
                min-width: 368px;

                input {
                    color: $darkGray !important;
                }

                &_disabled {
                    opacity: 1;
                }
            }

            > .newUpdateBtn {
                height: 48px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;
                padding: 12px 55px;
                background-color: $borderActive;
                border: 1px solid $borderActive;
            }
        }
    }

    > .line {
        height: 1px;
        margin-top: 32px;
        background-color: $borderLight;
    }

    > .bottomSection {

    }
}