.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold !important;
  font-size: 20px !important;
  margin-bottom: 12px !important;
}

.description {
  display: inline;
  width: fit-content;
  font-size: 16px;
  margin-bottom: 48px;
}

.link,
.link:active,
.link:focus,
.link:focus-within,
.link:hover,
.link:visited {
  font-size: inherit;
  width: fit-content;
  display: inline;
  color: #3956C7;
}

.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
}