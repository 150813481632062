@import '../../var.scss';

.downloadUnreadUpdateHistoryModal {
  width: 100%;

  > .downloadUnreadUpdateHistory {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    > .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $darkGray;
    }

    > .text {
      font-size: 16px;
      line-height: 24px;
      color: $darkGray;
      margin-top: 8px;

      &_error {
        color: $deleteRed;
      }
    }

    > .form {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      > .dateRange {
        height: 42px;
        margin-right: 16px;
        width: 100%;

        input {
            height: 42.5px !important;
            font-size: 14px !important;

            &::placeholder {
                font-size: 14px !important;
            }
        }
      }

      > .downloadBtns {
        width: 100%;
        margin-top: 55px;

        > .downloadFile {
          width: 100%;
          display: flex;
          background: $backgroundGray3;
          border-radius: 8px;

          &:hover {
            cursor: pointer;
          }

          &:not(:first-child) {
            margin-top: 16px;
          }

          > .left {
            padding: 12px 16px;
            border-radius: 8px 0 0 8px;
            background: $backgroundGray4;

            > .icon {

            }
          }

          > .right {
            flex: 1;
            padding: 12px 0;
            text-align: center;

            > .text {
              text-align: center;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: $darkGray;
            }
          }
        }
      }
    }

  }
}