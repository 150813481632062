@import "../../../var.scss";

.sideBar {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 24px 16px;
    border-radius: 16px;
    background-color: $white;
    overflow: hidden;
    min-height: 500px;

    .logo {
        margin-left: 11px;
        background-color: white;
        height: 32px;
    }

    .toggle {
        display: flex;
        align-items: center;
        margin-top: 32px;
        margin-left: 11px;

        &__btn {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            border: 1px solid $borderGray;
            border-radius: 50%;

            &:hover{
                cursor: pointer;
                background-color: $lightGray;
            }
        }

        &__text{
            font-size: 14px;
            line-height: 24px;
            color: $darkGray;
            white-space: nowrap;
        }
    }

    .nav {
        margin-top: 32px;

        &__item {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            text-decoration: none;

            
            &:not(:first-child){
                margin-top: 24px;
            }

            .text {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $gray;
                white-space: nowrap;
            }

            .iconWrapper{
                position: relative;
                margin-right: 16px;
                
                .icon {
                    > div {
                        background-color: $gray !important;
                    }
                }

                .newEvent {
                    position: absolute;
                    top: 1px;
                    right: 2px;
                    width: 6px;
                    min-height: 6px;
                    background-color: $statusHigh;
                    border-radius: 50%;
                }
            }

            &_active {
                border-radius: 8px;
                background-color: $primary01;
                margin: 0;

                .text {
                    color: $white;
                }

                .iconWrapper{
                    .icon {
                        > div {
                            background-color: white !important;
                        }
                    }
                }
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 24px;
        left: 0;
        width: 100%;
        padding: 0 16px;

        .line {
            height: 1px;
            background-color: $borderGray;
        }

        .loader {
            margin: 20px 0 0 20px;
        }

        .user {
            display: flex;
            align-items: center;
            margin-top: 24px;

            &:hover {
                cursor: pointer;
            }

            &__avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
                border-radius: 50%;
                background: $grayBg;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                color: $white;
                text-transform: uppercase;
            }

            &__photo {
                border-radius: 50%;
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
                object-fit: cover;
            }

            &__info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 16px;

                .name {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $darkGray;
                    white-space: nowrap;
                    text-transform: capitalize;
                }

                .role {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: $gray;
                }
            }
        }
    }
}