@import '../../var.scss';

.residentCard {
    position: relative;
    padding: 15px;
    background-color: $white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;

    &:hover {
        cursor: pointer;
    }
    &_selectable {
        border: 1px solid $borderGray;
        border-radius: 16px;
    }
    
    &_selected {
        border: 1px solid $borderLightBlue;
        border-radius: 16px;
    }

    .selectable {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $white;
        border: 1px solid $borderGray;
        display: flex;
        align-items: center;
        justify-content: center;

        &_selected {
            background-color: $borderLightBlue;
        }
    }


    > .confirmedIcon {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    > .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 16px;
        
        > .name {
            max-width: 160px;
            text-align: center;
            word-wrap: break-word;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $darkGray;
        }

        > .place {
            margin-top: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $darkGray;

            > .bold{
                font-weight: 600;
            }
        }

        > .line {
            width: 100%;
            height: 1px;
            background-color: $borderLightGray;
            margin: 16px 0;
            margin-top: auto;
        }

        > .underLine {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-self: flex-end;

            > .newUpdate {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $primary01;
    
                &:hover {
                    color: $hover01;
                }
            }
    
            > .update {
                margin-top: 4px;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: $gray;
            }

        }
    }

    &_lines {
        display: flex;
        align-items: center;
        flex-direction: row;

        .photo, > .noPhoto {
            height: 90px;
            width: 90px;
            min-width: 90px;
            min-height: 90px;
            border-radius: 50%;
            object-fit: cover;
        }

        > .info {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 16px;
            margin-left: 32px;

            > .name {
                white-space: nowrap;
            }
    
            > .line {
                width: 100%;
                height: 1px;
                background-color: $borderLightGray;
                margin: 8px 0;
            }

            > .underLine {
                width: 100%;
                flex-direction: row;
                justify-content: space-between;

                > .newUpdate {
                    order: 1;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: $primary01;
                    white-space: nowrap;
        
                    &:hover {
                        color: $hover01;
                    }
                }
        
                > .update {
                    white-space: nowrap;
                    order: 0;
                    margin-top: 4px;
                    margin-right: 4px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    color: $gray;
                }
    
            }
        }
    }
}

.photo, .noPhoto {
    margin: 0 auto;
    width: 108px;
    height: 108px;
    min-width: 108px;
    min-height: 108px;
    border-radius: 50%;
    object-fit: cover;
}

.noPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: $white;
}