@import "../../var.scss";
@import "../common.scss";

.superAdminPanel {
  background-color: $primary01;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @extend .page;
  
  > .sideBar {
    height: 755px;
    display: flex;
    flex-direction: column;

    background-color: $white;
    max-height: 755px;
    padding: 20px;
    border-radius: 20px;

    .nav__item {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray;
      white-space: nowrap;
      text-decoration: none;

      &:not(:first-child) {
        padding-top: 20px;
      }

      &_active {
        color: $primary01;
      }
  }
  }

  > .routerOutlet {
    min-width: 800px;
    max-height: 755px;
    background-color: $white;
    border-radius: 20px;
  }
}