.snackbar {
  &__inner {
    width: 100%;
    box-shadow: 0px 7px 15px rgba(185, 186, 205, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  &__closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
  }
}