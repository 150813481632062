@import "../../../../var.scss";

.residents {
    height: 100%;
    position: relative;
    
    > .filters {
        display: flex;
        justify-content: space-between;

        > .fields {
            display: flex;
            margin-right: 24px;
            padding-left: 8px;

            > .search {
                margin-right: 24px;
                max-width: 256px;
                min-width: 200px;
                height: 100%;

                > input {
                    font-size: 14px;
                    padding: 10px 12px 8px 40px;
                }

                &_placeholder {
                    div {
                        color: $gray;
                    }
                }
            }

            > .select {
                max-width: 172px;
                min-width: 60px;
                height: 40px !important;
                margin-left: 12px;
            }

            > .selectResidents {
                box-sizing: border-box;
                min-width: 252px;
                height: 40px;
                padding: 0px 12px;
                background: $backgroundGray3;
                border-radius: 8px;
                
                .container {
                    display: flex;
                    align-items: center;

                    > .icon {
                        margin-right: 4px;
                    }
    
                    > .text {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        color: $darkGray;
                    }
                }
            }

            > .cancelSelection {
                box-sizing: border-box;
                min-width: 186px;
                padding: 0px 12px;
                background: $backgroundGray3;
                border-radius: 8px;
                height: 40px;
                margin-right: 20px;

                .container {
                    display: flex;
                    align-items: center;

                    > .icon {
                    }
    
                    > .text {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        color: $darkGray;
                    }
                }
            }

            > .newUpdate {
                min-width: 140px;
                height: 40px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;
            }

            > .selectAll {
                margin-right: 20px;
                min-width: 140px;
                height: 40px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;
            }
        }
    
        > .gridFormat {
            display: flex;

            > .icon {
                > div {
                    background-color: $borderGray !important;
                }
                
                &:first-child {
                    margin-right: 16px;
                }

                &:hover {
                    cursor: pointer;
                }

                &_active {
                    > div {
                        background-color: $primary01 !important;
                    }
                }
            }
        }
    }

    > .grid {
        height: calc(100% - 64px);
        overflow-y: scroll;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(204px, auto));
        grid-auto-rows: minmax(auto, 330px);
        gap: 16px;
        padding-top: 16px;
        padding-left: 8px;

        &::-webkit-scrollbar {
            display: none;
        }

        &_lines {
            grid-template-columns: repeat(auto-fill, minmax(406px, auto));
            grid-auto-rows: minmax(auto, 150px);
        }
    }

    > .loading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 200px;
    }
}