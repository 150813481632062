.svg {
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
  transition: background-color 0.1s linear;
  display: inline-block;
}

.svgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}