.helperTooltip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
  padding: 24px 24px 24px 0 !important;
  background-color: white;

  &_tooltip {
    position: absolute;
    z-index: 10000;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
  }

  &__title {
    margin-left: 24px;
    font-size: 16px !important;
    font-weight: 500;
  }

  &__subTitle {
    margin-left: 24px;
  }

  &__ul {
    width: 100%;
    padding-left: 48px !important;

    li {
      font-weight: 300;
      list-style: circle outside !important;
    }
  }

  &__inner {
    width: 100%;
  }


  &::after {
    content: "\25B2";
    position: absolute;
    bottom: -21px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    color: white;
    font-size: 20px;
  }
}

.valid {
  color:#0DA12E;
}
