@import '../../../../var.scss';

.search {
  position: relative;
  width: 100%;
  height: 48px;
  border: 1px solid $border;
  border-radius: 8px;
  
  &__input {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 8px 12px 8px 40px;
    border: none;
    color: #7E808F;
    font-size: 16px;

    &_fill {
      background-color: $lightGray;
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
}