
@import '../../var.scss';

.modalCantCreateUpdate {
  .prmiraryContactNotActivated {
    display: flex;
    flex-direction: column;
    align-items: start;

    .row {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: $darkGray;

      &_bold {
        font-weight: 600;
        font-size: 20px;
        color: $darkGray;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  .noPrimaryContact {
    .row {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: $darkGray;
    }
  }

  .confirmBtn {
    margin: 0 auto;
    width: 218px;
    height: 50px;
    margin-top: 20px;
  }

}

.photo, > .noPhoto {
  height: 90px;
  width: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  object-fit: cover;
}
