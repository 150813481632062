@import "../../var.scss";
@import "../common.scss";

.login {
  background-color: $primary01;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .page;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &_logo {
      margin-bottom: 56px;
      width: 140px;
      height: 32px;
    }
  }

  &__form {
    padding: 40px;
    background-color: white;
    border-radius: 16px;
    max-width: 450px;
    width: 100%;
    z-index: 2;

    &_title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      text-align: center;
    }

    &_subTitle {
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      line-height: 24px;
      text-align: center;
    }

    &_input {
      margin-top: 24px;
    }

    &_btn {
      margin-top: 136px;
      width: 100%;
    }

    & > .forgot {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3956C7;
      width: 100%;
      text-align: center;
      cursor: pointer;
      width: fit-content;
      margin: 0 auto;
      margin-top: 16px;
    }
  }

  &__needHelp {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: $white;
    z-index: 2;

    &:hover {
      cursor: pointer;
    }
  }

  &_back {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
  }
}