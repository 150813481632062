@import "../../../../../var.scss";

.adminTable {
    display: block;
    width: 95%;
    margin: 0 auto;
    max-height: 755px;
    min-height: 320px;
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 20px -3px rgba(216, 216, 226, 0.2);
    background-color: #ffffff;
    
    .photoContainer {
        display: flex;
        align-items: center;

        .photo, .noPhoto {
            width: 32px;
            height: 32px;
            min-width: 32px; 
            min-height: 32px;
            border-radius: 50%; 
            margin-right: 8px;
        }

        .noPhoto {
            display: flex;
            justify-content: center; 
            align-items: center; 
            background-color: $grayBg; 
            color: $white; 
            font-weight: 600; 
            font-size: 12px;
        }

        .name {
            color: $darkGray;
            line-height: 32px;
            white-space: nowrap;
        }
    }

    .house {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 6px;
        }
        
        .text {
            color: $darkGray;
            font-size: 14px;
        }
    }

    .houseText {
        color: $darkGray;
        font-size: 14px;
    }

    .loader {
        display: flex;
        justify-content: center;
    }

    .pagination {
        
    }
}

.photo, .noPhoto {
    width: 32px;
    height: 32px;
    min-width: 32px; 
    min-height: 32px;
    border-radius: 50%; 
    margin-right: 8px;
}

.noPhoto {
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: $grayBg; 
    color: $white; 
    font-weight: 600; 
    font-size: 12px;
}