
@import '../../var.scss';

.loginTechSupportModalContainer {
  box-sizing: border-box;

  > .content {
    > .title {
      display: block;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: $darkGray;
    }

    > .subTitle {
      display: block;
      margin-top: 4px;
      font-size: 16px;
      line-height: 24px;
      color: $darkGray;
    }

    > .form {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > .input {
        width: 324px;
        margin-top: 12px;
      }

      > .textArea {
        margin-top: 20px;
      }

      > .button {
        width: 240px;
        height: 48px;
        margin-top: 12px;
        margin-left: auto;
      }
    }
  }
}