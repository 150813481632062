.modal {
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: rgb(225, 222, 222);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3956C7;
    border-radius: 8px;
  }

  &_close {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 2px solid #636466;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
  }
}

@media (max-width: 900px) {
  .modal {
    padding: 20px;
  }
}