@import "../../../../../var.scss";

.announcementsCard {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 0px 20px -3px rgba(216, 216, 226, 0.2);
    background-color: $white;

    > .info {
        > .head {
            display: flex;
            align-items: center;

            > .text{
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $gray;
                text-transform: capitalize;
            }

            > .dot {
                width: 4px;
                height: 4px;
                margin: 0 12px;
                background-color: $border;
            }

            > .adminActions {
                display: flex;
                margin-left: 26px;

                > .editAnnouncement {
                    display: flex;
                    align-items: center;
                    margin-right: 16px;
    
                    &:hover {
                        cursor: pointer;
                        color: $hover01;
                    }
    
                    > .icon {
                        margin-right: 9px;
                    }
    
                    > .text {
                        color: $primary01;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                
                > .deleteAnnouncement {
                    display: flex;
                    align-items: center;
    
                    &:hover {
                        cursor: pointer;
                    }
    
                    > .icon {
                        margin-right: 9px;
                    }
    
                    > .text {
                        color: $deleteRed;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        > .body {
            display: flex;
            flex-direction: column;
            margin-top: 8px;

            > .title {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: $darkGray;
            }

            > .text {
                font-size: 14px;
                line-height: 22px;
                color: $darkGray;
            }

            > .document {
                display: flex;
                align-items: center;
                margin-top: 14px;

                &:hover {
                    cursor: pointer;
                }

                > .icon {
                    margin-right: 8px;
                }

                > .text {
                    font-size: 16px;
                    line-height: 22px;
                    text-decoration-line: underline;
                    color: $darkGray;
                }
            }
        }
    }

}

.visual {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 220px;
    height: 120px;    
}

.picture {
    width: 220px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
}

.document {
    width: 60px;

    &:hover {
        cursor: pointer;
    }
}