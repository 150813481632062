@import "../../../../../var.scss";

.settingsBottomSection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(368px, auto));
    gap: 32px;
    margin-top: 32px;

    > .passwords {
        > .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $darkGray;
        }

        > .passwordForm {
            > .input {
                flex: 1;
                min-width: 368px;

                &:not(:first-child) {
                    margin-top: 32px;
                }

                &:first-child {
                    margin-top: 24px;
                }
            }

            > .btn {
                height: 48px;
                margin-top: 32px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: $white;
                padding: 12px 55px;
                background-color: $borderActive;
                border: 1px solid $borderActive;
            }
        }
    }

    > .photo {
        > .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $darkGray;
        }

        > .img {
            margin-top: 24px;
        }
    }
}