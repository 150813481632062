@import "../../../../var.scss";

.statisticTab {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 0 20px -3px rgba(216, 216, 226, 0.2);
  background-color: $white;
  & > h6 {
    margin-bottom: 6px;
  }
  & > div {
    margin-bottom: 28px;
  }

  > .filter {
    display: flex;
    align-items: center;

    > .dateRange {
      height: 42px;
      margin-top: -30px;
      min-width: 255px;
      margin-right: 16px;

      input {
        height: 42.5px !important;
        font-size: 14px !important;

        &::placeholder {
          font-size: 14px !important;
        }
      }
    }
  }

  > .content {
    display: flex;
    align-items: start;
    padding-top: 24px;

    > .updateColumn {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    > .line {
      background: $borderLight;
      width: 1px;
      height: 500px;
    }

    > .otherColumn {
      width: 50%;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}

.text {
  font-weight: 600;
  font-size: 16px;
  color: $darkGray;

  padding-top: 16px;

  > .number {
    font-weight: 600;
    font-size: 16px;
    color: $darkGray;
  }
}

.textHeader {
  font-weight: 600;
  font-size: 16px;
  color: $darkGray;

  > .number {
    font-weight: 600;
    font-size: 16px;
    color: $darkGray;
  }
}

.select {
  width: 50%;
}

.statisticFile {
  display: flex;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }

  .icon {
    margin-right: 8px;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: $darkGray;
  }
}