@import "../../var.scss";
@import "../common.scss";

.home {
  width: 100%;
  height: 100%;
  background-color: $lightGray;

  > .content{
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 24px 24px 0 24px;

    > .sideBar{
      width: 300px;
      min-width: 300px;
      height: 100%;
      transition: all 0.15s;
      padding-bottom: 24px;

      &_hidden {
        width: 80px;
        min-width: 80px;
      }
    }

    > .routerOutlet {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(100vw - 585px);
      height: 100%;
      flex-grow: 1;
      margin-left: 32px;

      &_hidden {
        width: calc(100vw - 180px);
      }

      > .header {

      }

      > .router {
        width: 100%;
        height: 100%;
        position: relative;
        flex-grow: 1;

        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
        > .inner {
        }
      }
    }
  }
}

.dropdown__text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: $darkGray;
}