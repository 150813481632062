
@import '../../var.scss';

.loginTwoFactorAuthModalContainer {
  box-sizing: border-box;
  width: 100%;

  > .content {
    display: flex;
    flex-direction: column;

    > .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-transform: capitalize;
      color: $darkGray;
    }

    > .line {
      margin-left: -32px !important;
      width: calc(100% + 64px);
      margin: 16px 0;
      border-top: 1px solid $borderLightGray;
    }

    > .requirements {
      display: flex;
      flex-direction: column;

      > .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $darkGray;
      }

      > .list {
        width: 375px;
        margin-left: 28px;
        columns: 2;
        list-style: disc !important;

        > .listItem {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $darkGray;
          list-style: disc !important;
        }
      }
    }

      > .qrCode {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 240px;
        margin: 24px auto;
        border: 1px solid $borderLightGray;
      }

      > .underQrCode {
        font-size: 14px;
        line-height: 22px;
        color: $darkGray;
      }

      > .form {
        margin-top: 16px;

        > .troubles {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          
          > .text {
            font-size: 14px;
            line-height: 22px;
            color: $darkGray;
          }

          > .key {
            padding-top: 4px;
            font-size: 14px;
            line-height: 22px;
            color: $primary01;
          }
        }

        > .buttons {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;

          .button {
            width: 252px;
            height: 48px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $white;

            &_close {
              width: 140px;
              height: 48px;
              // padding: 12 68px;
              background: $white;
              border: 1px solid $borderGray;
              border-radius: 8px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: $darkGray;
            }
          }
        }
      }
    }
  }