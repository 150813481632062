
@import '../../var.scss';

.expiredModalContainer {

  > .content {
    > .icon {
      margin: 0 auto;
    }

    > .textInfo {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      text-align: center;

      > .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: $darkGray;
      }

      > .text {
        margin-top: 4px;
        font-size: 16px;
        line-height: 24px;
        color: $darkGray;
      }

      > .timer {
        margin-top: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $darkGray;
      }
    }
  }

  .continueBtn {
    margin: 0 auto;
    width: 218px;
    height: 48px;
    margin-top: 28px;
  }
}