@import "../../../../var.scss";

.input {
  position: relative !important;
  width: 100% !important;

  &__field {
    color: #7E808F !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    transition: box-shadow 0.13s ease-in-out;

    &_input {
      box-sizing: border-box;
      width: 100% !important;
      height: 48px !important;
      border: 1px solid $border;
      border-radius: 8px !important;
      color: #7E808F !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      transition: border-color 0.05s linear;

      input {
        height: 100% !important;
        padding: 0 14px !important; 
      }

      &:hover {
        border-color: $borderHover;
        transition: border-color 0.05s linear;
      }

      fieldset {
        border-width: 0px !important;
        font-size: 16px;
        font-weight: 300;
      }
    }
  
    &_active {
      border: 1px solid $borderActive !important;
      box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0.07) !important;
      transition: box-shadow 0.13s ease-in-out !important;

      &:hover {
        border-color: $borderActive;
      }
    }

    &_error {
      border: 1px solid $warning;
      box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0);
      transition: box-shadow 0.13s ease-in-out;

      &:hover {
        border-color: $warning;
      }
    }
  }
}

.textInputWrapper {
  position: relative;
  width: 100%;

  &_label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    span {
      color: $warning;
    }
  }

  &_error {
    position: absolute;
    bottom: -17px;
    right: 0;
    color: $warning;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
