@import '../../var.scss';

.deactivateResidentReasonModal {
  width: 100%;

  > .deactivateResidentReason {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    > .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $darkGray;
    }

    > .text {
      font-size: 16px;
      line-height: 24px;
      color: $darkGray;
      margin-top: 8px;

      &_error {
        color: $deleteRed;
      }
    }

    > .form {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      > .form_input {
        margin-top: 16px;
      }

      > .confirmBtns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
  
        > .cancelBtn {
          height: 48px;
          margin-right: 16px;
          padding: 0 68px;
          background: $lightGray;
          border: 1px solid $borderGray;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $darkGray;
        }
        
        > .form_btn {
          padding: 0 45px;
          height: 48px;
        }

        > .disableResidentModal {
          > .form_btn {
            padding: 0 45px;
            height: 48px;
          }
        }
      }
    }

  }
}