@import "../../../../var.scss";

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  &_inner {
    
  }

  &_label {
    padding: 5px;
    font-size: 16px;
    font-weight: 400;
    margin-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      overflow: visible;
      background-color: transparent !important;
      z-index: 10000;
    }

    &_disabled {
      opacity: 0.26;
    }
  }
}
