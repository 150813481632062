@import "../../../../../../var.scss";

.tableToolbar{
    display: flex;
    align-items: center;
    background-color: $white;

    .filterBar {
        display: flex;
        align-items: center;
        width: 100%;
    
        > .search {
            max-width: 256px;
            height: 43px;
        }
    }
}