@import "../../../../var.scss";

.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.1s ease-in-out !important;
  text-decoration: none;

  &__childrenWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 0 6px;

    // span {
    //   padding-right: 5px;
    // }
  }

  &_small {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
  }

  &_medium {
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &_large {
    height: 56px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__contained {
    background-color: $primary01;
    color: white;
    transition: all 0.1s linear;

    &:hover {
      background-color: $hover01;
      transition: inherit;
    }

    &:active {
      background-color: $primary01;
      transition: inherit;
    }

    &:focus {
      box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0.07) !important;
      transition: inherit;
    }

    &_active {
      background-color: $primary01;
      transition: inherit;
      box-shadow: none;

      &:hover {
        background-color: $primary01;
        box-shadow: inherit;
      }

      &:active {
        background-color: $primary01;
        box-shadow: inherit;
      }
    }

    &_inActive {
      background-color: $primary01;
      box-shadow: none;
      opacity: 0.3;
      transition: inherit;

      &:hover {
        background-color: $primary01;
        box-shadow: inherit;
      }

      &:active {
        background-color: $primary01;
        box-shadow: inherit;
      }
    }

  }

  &__outlined {
    border: 1px solid $primary01;
    color: $primary01;
    background-color: transparent;
    transition: all 0.1s linear;

    &:hover {
      background-color: $primary01;
      transition: inherit;
      color: white;
    }

    &:active {
      background-color: $hover01;
      transition: inherit;
      color: white;
    }

    &:focus {
      box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0.07) !important;
      transition: inherit;
    }

    &_active {
      background-color: $primary01;
      transition: inherit;
      box-shadow: none;

      &:hover {
        background-color: $primary01;
        box-shadow: inherit;
        color: inherit;
      }

      &:active {
        background-color: $primary01;
        box-shadow: inherit;
        color: inherit;
      }
    }

    &_inActive {
      border: 1px solid $primary01;
      background-color: transparent;
      color: $primary01;
      transition: all 0.1s linear;
      box-shadow: none;
      opacity: 0.3;

      &:hover {
        box-shadow: inherit;
        border: 1px solid $primary01;
        background-color: transparent;
        color: $primary01;
      }

      &:active {
        box-shadow: inherit;
        border: 1px solid $primary01;
        background-color: transparent;
        color: $primary01;
      }
    }
  }

  &__additional {
    background-color: #EBEEF9;
    border: 1px solid #EBEEF9;
    color: #333333;
    transition: all 0.1s linear;

    &:hover {
      background-color: #dde0eb;
      transition: inherit;
    }

    &:active {
      background-color: #EBEEF9;
      transition: inherit;
    }

    &:focus {
      box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0.02) !important;
      transition: inherit;
    }

    &_active {
      background-color: #EBEEF9;
      transition: inherit;
      box-shadow: none;

      &:hover {
        background-color: #EBEEF9;
        box-shadow: inherit;
      }

      &:active {
        background-color: #EBEEF9;
        box-shadow: inherit;
      }
    }

    &_inActive {
      background-color: #EBEEF9;
      box-shadow: none;
      opacity: 0.3;
      transition: inherit;

      &:hover {
        background-color: #EBEEF9;
        box-shadow: inherit;
      }

      &:active {
        background-color: #EBEEF9;
        box-shadow: inherit;
      }
    }
  }
  
}
