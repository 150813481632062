@import "../../../../var.scss";

.selectInputWrapper {
  position: relative;
  width: 100%;

  > .autocomplete {
    > .option {
      color: red !important;
    }
    > .textfield {
      font-size: 30px !important;

      &_input {
        box-sizing: border-box;
        width: 100% !important;
        border: 1px solid #DDE2F2;
        border-radius: 8px !important;
        color: #7E808F !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        transition: border-color 0.05s linear;
        height: 48px !important;
  
        input {
          height: 100% !important;
          padding: 0 14px !important;
        }
  
        &:hover {
          border-color: $borderHover;
          transition: border-color 0.05s linear;
        }
  
        fieldset {
          border-width: 0px !important;
          font-size: 16px;
          font-weight: 300;
        }
  
        &_multiLine {
          height: unset !important;
        }
  
  
      }
    
      &_active {
        border: 1px solid $borderActive !important;
        box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0.07) !important;
        transition: box-shadow 0.13s ease-in-out !important;
  
        &:hover {
          border-color: $borderActive;
        }
      }

      &_error {
        border: 1px solid $warning;
        box-shadow: 0 0 8px 4px rgba(20, 0, 255, 0);
        transition: box-shadow 0.13s ease-in-out;
  
        &:hover {
          border-color: $warning;
        }
      }
  
      &_disabled {
        border-radius: 8px;
        background-color: #F9F9FC !important;
  
        input {
          padding: 0 14px !important;
        }
      }
    }
  }

  &__placeHolder {
    position: absolute;
    bottom: 11px;
    left: 12px;
    color: #bbbeca;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }

  &_label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    span {
      color: $warning;
    }
  }

  &_error {
    position: absolute;
    bottom: -17px;
    right: 0;
    color: $warning;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &_disabled {
    opacity: 0.5;
  }
}

.textInputWrapper {
  position: relative;
  width: 100%;
  border-radius: 8px;

  &_label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    span {
      color: $warning;
      margin-left: 2px;
    }
  }

  &_subLabel {
    font-size: 12px;
    color: #6a6b74;
    white-space: break-spaces;
    margin-bottom: 10px;
  }

  &_error {
    position: absolute;
    top: 3px;
    right: 0;
    color: $warning;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &_length {
    position: absolute;
    bottom: -25px;
    right: 0;

    > .text {
      color: #6a6b74;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    &_error {
      > .text {
        color: $warning;
      }
    }
  }

  &_disabled {
    opacity: 0.5;
  }
}
