@import "../../../../var.scss";

.historyChat {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: $white;
    border-radius: 16px;

    > .loading {
        margin-top: 100px;
        
        > .loader {
            margin: 0 auto;
        }
    }

    > .sideBar {
        width: 34%;
        height: 100%;
        border-right: 1px solid $borderLightGray;

        > .searchChat {
            padding: 16px;
        }
        
        > .chatList {
            flex-grow: 1;
            max-height: calc(100% - 80px);
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            > .loader {
                margin: 100px auto;
            }

            > .chat {
                &_active {
                    background: $lightGray;
                    pointer-events: none;
                }
        
                &:not(:last-child) {
                    border-bottom: 1px solid $borderLightGray;
                }

                &:hover {
                    cursor: pointer;
                }

                > .content {
                    display: flex;
                    padding: 16px;

                    > .photo, > .noPhoto {
                        width: 48px;
                        min-height: 48px;
                        max-height: 48px;
                        border-radius: 50%;
                        margin-right: 16px;
                        object-fit: cover;
                    }
            
                    > .noPhoto {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 48px;
                        min-height: 48px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: $white;
                        object-fit: cover;
                    }
            
                    > .body {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
            
                        > .textInfo {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            margin-right: 4px;
            
                            > .name {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: $darkGray;
                            }
            
                            > .lastMessage {
                                min-height: 40px;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                font-size: 14px;
                                line-height: 20px;
                                color: $textLightGray;
                            }
                        }
            
                        > .otherInfo {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            
                            > .date {
                                font-size: 12px;
                                line-height: 24px;
                                color: $textLightGray;
                            }
            
                            > .unreadMessages {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                background: $deleteRed;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 24px;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    > .chat {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        > .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $borderLightGray;

            > .left {
                width: 60%;
                padding: 16px;

                > .familyMember {
                    display: flex;

                    > .coupleMembersPhoto {
                        display: flex;
                        position: relative;
                        margin-right: 16px;

                        > .photo {
                            width: 48px;
                            min-height: 48px;
                            border: 1px solid $white;
                            border-radius: 50%;
                            object-fit: cover;

                            &:not(:first-child) {
                                margin-left: -24px;
                            }
                        }

                        > .noPhoto {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-width: 48px;
                            min-height: 48px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: $white;
                            background-color: #22BEE0;
                            border: 1px solid $white;
                            border-radius: 50%;

                            &:not(:first-child) {
                                margin-left: -24px;
                            }
                        }
                    }

                    > .photo, > .noPhoto {
                        width: 48px;
                        min-height: 48px;
                        border-radius: 50%;
                        margin-right: 16px;
                        object-fit: cover;
                    }
            
                    > .noPhoto {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 48px;
                        min-height: 48px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: $white;
                        background-color: #22BEE0;
                    }

                    > .textInfo {
                        display: flex;
                        flex-direction: column;
                        
                        > .name {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: $darkGray;
                        }

                        > .role {
                            font-size: 14px;
                            line-height: 22px;
                            color: $textLightGray;
                        }
                    }
                }
            }

            > .right {
                padding: 16px;
                border-left: 1px solid $borderLightGray;

                > .resident {
                    display: flex;

                    > .photo, > .noPhoto {
                        width: 48px;
                        min-height: 48px;
                        border-radius: 50%;
                        margin-left: 16px;
                        object-fit: cover;
                    }
            
                    > .noPhoto {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-width: 48px;
                        min-height: 48px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: $white;
                        background-color: #22BEE0;
                    }

                    > .textInfo {
                        display: flex;
                        flex-direction: column;

                        > .name {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: $darkGray;
                        }

                        > .place {
                            font-size: 14px;
                            line-height: 22px;
                            color: $darkGray;

                            > .bold {
                                font-weight: 600;
                            }
                        }
                    }
                }

            }
        }

        > .list {
            width: 100%;
            flex-grow: 1;
            overflow-y: scroll;
            margin-bottom: 16px;
            padding: 16px;
    
            &::-webkit-scrollbar {
                display: none;
            }
    
            > .message {
                display: flex;
                width: 100%;
    
                &:not(:first-child) {
                    margin-top: 16px;
                }
    
                &_right {
                    justify-content: flex-end;
    
                    .messageContainer {
                        .photo, .noPhoto {
                            order: 2;
                            margin-left: 16px;
                            margin-right: 0;
                            object-fit: cover;
                        }
    
                        .body{
                            order: 1;
                            background: $backgrounLightPurple !important;
                            border-radius: 16px 16px 0 16px !important;
                        }
                    }
                }
    
                > .messageContainer {
                    width: 65%;
                    display: flex;
                    align-items: flex-end;
                    
                    &:not(:first-child) {
                        margin-top: 16px;
                    }

                    > .photo, .noPhoto {
                        width: 48px;
                        min-height: 48px;
                        min-width: 48px;
                        border-radius: 50%;
                        margin-right: 16px;
                        object-fit: cover;
                    }
    
                    > .noPhoto {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        max-height: 48px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: $white;
                        background-color: #22BEE0;
                    }
    
                    > .body {
                        width: 100%;
    
                        background: $lightGray;
                        border-radius: 16px 16px 16px 0px;
                        padding: 16px 8px 4px 16px;
    
                        > .text {
                            display: flex;
                            flex-direction: column;
    
                            > .sender {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: $darkGray;
                                text-transform: capitalize;
                            }
    
                            > .message {
                                margin-top: 8px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: $darkGray;
                            }
                        }
    
                        > .timeRead {
                            display: flex;
                            float: right;

                            > .time {
                                font-weight: 400;
                                font-style: italic;
                                font-size: 14px;
                                line-height: 24px;
                                color: $gray;
                                white-space: nowrap;
                            }

                            > .iconRead {
                                margin-left: 8px;
                            }
                        }
                    }
    
                }
            }
        }
    
        > .writeMessage {
            position: relative;
            padding: 16px;
            border-radius: 42px;
            margin-bottom: 16px;
            background: $white;

            div {
                border: none !important;
                box-shadow: none !important;
            }
            
            input {
                background-color: #F3F4FF;
                border-radius: 42px;
                border: none !important;
            }

            > .newMessage {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: $primary01;

                &:hover {
                    cursor: pointer;
                    background-color: $hover01;
                }
            }
        }
    }
}